import Axios from 'axios';

/**
 * @typedef {{
 *   type: string,
 *   view?: Record<string, any>,
 *   filter?: Record<string, any>,
 *   count?: number
 * }} EffectQuery
 */

/**
 * @typedef {{
 *   version: string,
 *   results: Record<string, EffectQueryReply>
 * } | {
 *   version: string,
 *   error: EffectQueryError
 * }} EffectSearchResponse
 */

/**
 * @typedef {{ adverts: T[] } | { error: EffectQueryError }} EffectQueryReply<T>
 */

/**
 * @typedef {{
 *   title: string,
 *   gal: string,
 *   image: {
 *     url: string
 *   },
 *   link: {
 *     url: string
 *   }
 * }} FeaturerAdvert
 */

/**
 * @typedef {{
 *   title: string,
 *   color: string,
 *   link: {
 *     url: string
 *   },
 *   events: {
 *     view: string
 *   }
 * }} MegapostAdvert
 */

/**
 * @typedef {{
 *   code: number,
 *   message: string,
 * }} EffectQueryError
 */

class EffectApi {
  constructor() {
    this.client = Axios.create({
      baseURL: document.querySelector('meta[name="effect_url"]').content,
      transformRequest: [
        (data) => {
          // eslint-disable-next-line no-param-reassign
          data.version = '1.0';

          return JSON.stringify(data);
        },
      ],
    });
    delete this.client.defaults.headers.common['Content-Type'];
  }

  /**
   * @param {string} fl
   * @returns {Promise<EffectSearchResponse<{ feature: FeaturerAdvert, megaposts: MegapostAdvert }>>}
   */
  getEffectDataForHeader({ count = 1, fl = 'ru' } = {}) {
    return this.search({
      feature: {
        type: 'featurer',
        view: {
          lang: fl,
        },
      },
      megaposts: {
        type: 'megapost',
        filter: {
          langs: [fl],
        },
        count,
      },
    }).then(({ results }) => results);
  }

  trackView(eventId) {
    return this.client.request({
      method: 'POST',
      data: { version: '1.0', events: [eventId] },
    });
  }

  /**
   * @param {Record<string, EffectQuery>} queries
   * @returns {Promise<EffectSearchResponse<FeaturerAdvert>>}
   */
  search(queries) {
    return this.client
      .request({
        method: 'SEARCH',
        data: {
          queries,
        },
      })
      .then(({ data }) => data);
  }
}

export const effectResource = new EffectApi();
