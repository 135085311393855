import { Pusher } from '@habralab/pusher';

import { apiUrlV1, cancellableRequest } from 'src/services/api.js';
import { captureException } from 'src/utils/sentry.js';

/**
 * @typedef {import('@habralab/pusher/dist/index').CareerPusherEventMap} CareerPusherEventMap
 */

async function JWTTokenGetter() {
  const response = await cancellableRequest({
    method: 'GET',
    url: `${apiUrlV1}/users/pusher_token`,
  });

  return response.data.token;
}

function getPusherURI() {
  if (window.location.hostname === 'career.habr.com')
    return 'wss://pusher.habr.com:8443/ws/career';

  return 'wss://rc.pusher.habratest.net/ws/career';
}

function onUserUnreadChatsUpdated({ data: { unreadChatsCount } }) {
  let oldUnreadChatsValue = 0;

  /**
   * Обновляем счетчики непрочитанных диалогов в шаблонах _userMenu.html.slim, _userPanel.html.slim
   */
  const counterElements = document.querySelectorAll(
    '[data-conversations-counter]',
  );

  counterElements.forEach((counterElement) => {
    const counterContentElement = counterElement.querySelector(
      '[data-counter-badge-content]',
    );

    if (!counterContentElement) return;

    oldUnreadChatsValue = parseInt(counterElement.textContent, 10) || 0;

    if (unreadChatsCount) {
      counterContentElement.textContent = unreadChatsCount;
      counterElement.classList.remove('hidden');
    } else {
      counterContentElement.textContent = 0;
      counterElement.classList.add('hidden');
    }
  });

  /**
   * Обновляем общий счетчик непрочитанных уведомлений (актуально для мобильной версии) _tm_panel.html.slim
   */
  const summaryElements = document.querySelectorAll('[data-summary-counter]');

  summaryElements.forEach((summaryElement) => {
    const counterContentElement = summaryElement.querySelector(
      '[data-counter-badge-content]',
    );

    if (!counterContentElement) return;

    const currentSummaryValue =
      parseInt(counterContentElement.textContent, 10) || 0;
    const newSummaryValue =
      currentSummaryValue + (unreadChatsCount - oldUnreadChatsValue);

    if (newSummaryValue > 0) {
      summaryElement.classList.remove('hidden');
      counterContentElement.textContent = newSummaryValue;
    } else {
      summaryElement.classList.add('hidden');
      counterContentElement.textContent = 0;
    }
  });
}

function initUpdateUnreadChatsCounter() {
  try {
    if (!window.app.isUserLoggedIn) return;

    /**
     * @type {Pusher<CareerPusherEventMap>}
     */
    const pusher = new Pusher({
      uri: getPusherURI(),
      JWTTokenGetter,
    });

    pusher.on('user_unread_chats_updated', onUserUnreadChatsUpdated);

    pusher.connect();
  } catch (error) {
    console.error(error);
    captureException(error, { label: 'pusher' });
  }
}

document.addEventListener('DOMContentLoaded', initUpdateUnreadChatsCounter);
