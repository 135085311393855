import 'what-input';

import 'src/parts/company_score.js';
import 'src/parts/header.js';
import 'src/parts/tm_panel.js';
import 'src/parts/ga_events.js';
import 'src/parts/top_notice.js';
import 'src/parts/new_notice.js';
import 'src/parts/decode_punycode_inputs.js';
import { trackEvent, trackEventByName } from 'src/global-helpers.js';

import 'src/parts/update_unread_chats_counter';
import 'src/components/MenuHead/MenuHead.sass';

import 'styles/libs/normalize.css';
import 'styles/responsive.sass';
import 'styles/globals.sass';
import 'styles/views/layouts/application.css.sass';
import 'styles/views/layouts/header.sass';
import 'styles/views/layouts/user_panel.sass';
import 'styles/views/layouts/_footer.css.sass';
import 'styles/views/layouts/_top_notice.css.sass';
import 'styles/views/shared/sidebar/blog.css.sass';
import 'styles/parts/icons.css';
import 'styles/parts/tm_panel.sass';
import 'styles/parts/vacancy_banner.css.sass';
import 'styles/parts/buttons.css.sass';

window.helpers = {
  trackEvent,
  trackEventByName,
};
