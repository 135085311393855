import { apiUrlV1, cancellableRequest } from 'src/services/api.js';

export const BannerResource = {
  getBanner() {
    return cancellableRequest({
      method: 'get',
      url: `${apiUrlV1}/marketing/banner`,
    });
  },
  sendBannerAction(params) {
    return cancellableRequest({
      method: 'post',
      url: `${apiUrlV1}/marketing/banner_action`,
      data: params,
    });
  },
}