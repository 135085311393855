<template>
  <div v-if="isShowNotice" class="new-notice" ref="newNotice">
    <div class="new-notice__card">
      <div class="new-notice__emoji">{{ banner.emoji }}</div>
      <div class="new-notice__info">
        <div class="new-notice__title">{{ banner.title }}</div>
        <div class="new-notice__description" v-html="banner.content"></div>
      </div>
      <button class="new-notice__close" @click="closeNotice">
        <SvgIcon icon="cross" :size="12" color="ui-green" />
      </button>
    </div>
  </div>
</template>

<script>
import { captureException } from 'src/utils/sentry.js';

import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';

import { BannerResource } from 'src/services/banner';

export default {
  name: 'NewNotice',
  components: { SvgIcon },
  data() {
    return {
      banner: null,
      trigger: null,
      isShowNotice: false,
      clicksCount: 0,
    };
  },
  async mounted() {
    try {
      const response = await BannerResource.getBanner();

      if (!response.data) return;

      this.banner = response.data.banner;
      this.trigger = response.data.trigger;

      if (!this.trigger) return;

      if (this.trigger.type === 'time') {
        setTimeout(() => {
          this.showNotice();
        }, this.trigger.value * 1000);
      }
      if (this.trigger.type === 'click') {
        document.addEventListener('click', this.countClicks);
      }
    } catch (error) {
      captureException(error);
    }
  },
  methods: {
    countClicks(event) {
      if (event.target.tagName === 'INPUT') {
        return;
      }
      this.clicksCount += 1;
      if (this.clicksCount === this.trigger.value) {
        this.showNotice();
        document.removeEventListener('click', this.countClicks);
      }
    },
    closeNotice() {
      this.isShowNotice = false;
      BannerResource.sendBannerAction({
        banner: { id: this.banner.id, action: 'close' },
      });
    },
    viewNotice() {
      BannerResource.sendBannerAction({
        banner: { id: this.banner.id, action: 'view' },
      });
    },
    async showNotice() {
      this.isShowNotice = true;
      this.viewNotice();

      await this.$nextTick();
      const links = this.$refs.newNotice.getElementsByTagName('a');
      Array.from(links).forEach((link) => {
        link.addEventListener('click', () => {
          BannerResource.sendBannerAction({
            banner: { id: this.banner.id, action: 'click' },
          });
        });
      });
    },
  },
};
</script>
