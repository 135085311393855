import "src/components/overlays/BasicOverlay/BasicOverlay.js";
import "src/components/BasicDropdown/BasicDropdown.js";
import {initTooltips} from "src/components/BasicTooltip/BasicTooltip.js";

// import 'src/components/header/UserNotifications/UserNotifications.js';
// import 'src/components/header/FriendshipRequests/FriendshipRequests.js';

require.context("src/components", true, /(.sass$|.css$)/);
initTooltips();
// Порядок импортов здесь важен
/* eslint-disable-next-line */
import "styles/transitions.css";
