document.addEventListener('DOMContentLoaded', async () => {
  const punycodeInputs = Array.from(
    document.querySelectorAll('.js-convert-punycode'),
  );

  if (!punycodeInputs.length) return;

  const punycode = await import('punycode');

  punycodeInputs.forEach((input) => {
    input.addEventListener('paste', () => {
      // Без setTimeout в input.value будет старое значение
      setTimeout(() => {
        // определяем по "xn--" что возможно строка punycode
        if (!input.value.includes('xn--')) return;

        // декодер punycode плохо дружит с любым текстом кроме непосредственно самого punycode,
        // поэтому декодируем только хостнейм
        // также оборачиваем в try/catch на случай вставки в поле невалидного URL
        try {
          const url = new URL(input.value);
          const decodedValue = `${url.protocol}//${punycode.toUnicode(
            url.hostname,
          )}${decodeURI(url.pathname)}`;

          // eslint-disable-next-line
          input.value = decodedValue;
        } catch (error) {
          // nothing
        }
      }, 0);
    });
  });
});
