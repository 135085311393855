import { createVueApp } from 'src/utils/vue.js';

import NewNotice from 'src/components/NewNotice/NewNotice.vue';

const el = document.querySelector('[data-new_notice]');

if (el) {
  const app = createVueApp({
    render(h) {
      return h(NewNotice, {})
    },
  });

  app.$mount(el);
}